.featured-resources figure {
    max-width: 20em;
    max-height: 20em;
}

.splash-bar.flip-splash {
    margin-top: -1px;
    margin-bottom: -1px;
}

ul.social-sidebar li {
    list-style: none !important;
}

.detail.content-main header div {
    padding-top: 0;
    padding-bottom: 0;
}
